/* styles.css */

@import '~@fortawesome/fontawesome-free/css/all.css';

/* Regular navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #10162b;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 9999;
}


.logo img {
  max-width: 100px;
}

.nav-links {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 3rem;
  margin-right: auto;
}

.nav-links.open {
  display: flex;
  list-style-type: none;
  margin: 0;
  margin-right: auto;
}

.nav-links li {
  position: relative;
}

.nav-links li a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  display: block;
}

.nav-links .dropdown a {
  padding: 10px 30px 10px 10px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333;
  padding: 10px;
  min-width: 150px;
  border-radius: 10px;
  z-index: 1;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

@-moz-keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@-webkit-keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@-o-keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 5px 0;
}

.dropdown-content a:hover {
  background-color: #555;
}

/* Hamburger menu styles */
.menu-toggle {
  display: none;
  /* Hide by default */
  cursor: pointer;
}

.line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px 0;
}

/* Open class for mobile */
.nav-links.open {
  display: flex;
  flex-direction: column;
  background-color: #10162b;
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  padding: 0;
}

.nav-links.open li {
  padding: 10px;
  position: relative;
}

.nav-links.open .dropdown-content {
  display: none;
  position: static;
  background-color: #333;
  padding-left: 1rem;
  min-width: 100%;
}

.nav-links.open .dropdown:hover .dropdown-content {
  display: block;
}

.menu-toggle.open .line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-toggle.open .line:nth-child(2) {
  opacity: 0;
}

.menu-toggle.open .line:nth-child(3) {
  transform: rotate(-45deg) translate(5.6px, -6.5px);
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    /* Show hamburger menu */
  }

  .nav-links {
    display: none;
    /* Hide regular menu items */
  }

  .nav-links.open li {
    padding: 10px;
  }
}


.widget-card {
  background-color: lightgray;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 15px;
}

.home-container {
  padding: 20px;
}


.table-container {
  position: relative;
  margin-top: 20px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* Adjust the height as needed */
}

.stock-table {
  width: 100%;
  border-collapse: collapse;
}

.stock-table th,
.stock-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.stock-table td {
  background-color: #fff;
}
.stock-table.Bearish td {
  background-color: #ffe0e0;
}
.stock-table.Bullish td {
  background-color: #e8ffe4;
}


.stock-table th {
  background-color: #060032;
  color: #fff;
}

/* Sticky Header Styles */
.stock-table thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.section {
  background: transparent;
  position: relative;
  display: block;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  border-radius: 0;
}

.section.team {
  background: transparent;
  position: relative;
  display: block;
  overflow: hidden;
  padding-top: 70px;
  padding-bottom: 0;
  border-radius: 0;
}

.section.slanty-section {
  padding-top: 400px;
  padding-bottom: 400px;
  margin-top: -400px;
  margin-bottom: -400px;
  overflow: hidden;
  border: none;
}

.section .slant.blue-fade {
  background: #375997;
  background: linear-gradient(135deg, #182e45 0%, #004794 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#182e45', endColorstr='#004794', GradientType=1);
}

.section .slant {
  width: 200%;
  margin-left: -50%;
  padding-right: 50%;
  position: relative;
  -webkit-transform: rotate(-6deg);
  transform: rotate(-6deg);
}

.section.slanty-section .slant>div {
  -webkit-transform: rotate(6deg);
  transform: rotate(6deg);
}

.container {
  z-index: 3;
  width: 100%;
  max-width: 1320px;
  min-height: 25px;
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;
  padding: 35px;
}


@media only screen and (max-width: 1390px) {
  .container {
    z-index: 3;
    width: 90%;
    max-width: 1320px;
    min-height: 25px;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    padding: 35px;
  }

  .container.text-white {
    z-index: 3;
    width: 45%;
    max-width: 1320px;
    min-height: 25px;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    padding: 35px;
  }

  .container .content-pair-text.jobs {
    margin-right: 5%;
  }

  .container.text-white .content-pair-text.jobs {
    margin-left: 7%;
  }
}

.text-white,
.text-white span:not(.btn span):not(.customer-review *),
.text-white td .fa,
.text-white h2,
.text-white h2 .fa {
  color: #fff !important;
}

.content-pair {
  padding: 60px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.content-pair-collage {
  position: relative;
  width: 560px;
}

.content-pair-collage img {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(53, 105, 128, 0.3), 0 6px 20px 0 rgba(165, 200, 213, 0.41);
  z-index: 1;
  position: relative;
}

.container img {
  position: relative;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}

.section.slanty-section .slant .content-pair-collage .bubbles {
  opacity: .1;
}

.content-pair-collage .bubbles {
  position: absolute;
  left: -40px;
  top: -40px;
  width: calc(100% + 80px);
  height: calc(100% + 80px);
  background: #deeeff;
  z-index: 0;
  border-radius: 8000px 400px 400px 400px;
  opacity: .6;
}

.content-pair.right-image .bubbles {
  border-radius: 400px 8000px 400px 400px;
}

.content-pair-text.jobs {
  margin-left: 0;
}

.content-pair-text {
  width: 530px;
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.team_container {
  text-align: center;
  background-color: transparent;
  background-image: linear-gradient(180deg, #ffffff 11%, #DEECFF 88%);
  margin-top: 10%;
  padding-bottom: 70px;
}

.team_members {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
}

.team_member {
  flex-basis: 200px;
  /* Adjust according to your design */
}

.team_member_image {
  width: 100%;
  max-width: 250px;
  height: auto;
  border-radius: 5% 5% 5% 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.team_member_name {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.team_member_position {
  color: #555;
}

.privacy-policy-container {
  padding: 2rem 10rem 10rem 10rem;
}

.our-team-container {
  padding: 2rem 10rem 0 10rem;
}


@media only screen and (max-width: 767px) {
  .container {
    padding: 0 15px;
  }

  .content-pair {
    display: block;
  }

  .container .content-pair-text.jobs {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .container.text-white .content-pair-text.jobs {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .content-pair.right-image .content-pair-collage {
    width: 75%;
    max-width: 400px;
    margin: 0 auto;
  }

  .content-pair.right-image {
    display: flex;
    flex-flow: column;
  }

  .content-pair.right-image .content-pair-collage {
    order: 1;
  }

  .content-pair.right-image .content-pair-text.jobs {
    order: 2;
    width: 90%;
    margin-left: -1rem;
    padding: 2rem;
  }

  .content-pair .content-pair-text.jobs {
    width: 90%;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 2rem;
  }

  .content-pair .content-pair-collage {
    width: 75%;
    max-width: 400px;
    margin: 0 auto;
  }

  .fr-view img {
    margin-right: auto !important;
    margin-left: auto !important;
    height: auto !important;
    float: none !important;
    display: block;
  }

  .content-pair-collage .bubbles {
    width: 90%;
    left: 0;
  }

  .container img {
    margin-right: auto !important;
    margin-left: auto !important;
    height: auto !important;
    float: none !important;
    display: block;
  }

  .team_members {
    flex-direction: column;
    align-items: center;
  }

  .team_member {
    margin-bottom: 20px;
  }

  .privacy-policy-container {
    padding: 2rem 1rem 2rem 1rem;
  }
}